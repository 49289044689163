<template>
  <div class="myScore">
    <div class="title">
      <div class="left">
        <div class="icon">
          <img src="@/assets/imgs/my/p2_1.png" alt="" />
        </div>
        <div class="name">我的积分</div>
      </div>
    </div>
    <div class="content" v-if="haveLogin">
      <div class="lvPro_box">
        <div class="pros">
          <div class="lv">
            <span>{{ userSigns.levels.level }}</span>
            <span>{{ userSigns.levels.points }}</span>
          </div>
          <div class="pro_bar">
            <div
              class="bar"
              :style="`width: ${userSigns.levels.progress}`"
            ></div>
          </div>
        </div>
        <!-- <div class="goDuihuan" @click="goDuihuan">兑换商品》</div> -->
      </div>
      <div class="t_tips">做任务解锁特权和奖励</div>
      <div class="score_box">
        <div class="top_t">
          <span :class="{'bold':tab==1}" @click="chooseTab(1)">积分明细</span>
          <div class="right" :class="{'bold':tab==2}" @click="chooseTab(2)">积分规则》</div>
        </div>
        <div v-if="tab == 1">
          <div
            v-if="list.length == 0"
            style="text-align: center; margin: 220px 0"
          >
            暂无积分明细
          </div>
          <div class="list">
            <div
              class="item"
              v-for="(item, key) in list"
              :key="key"
              v-if="key < showNum"
            >
              <div class="left">
                <div class="name">{{ item.title }}</div>
                <div class="time">{{ item.date }}</div>
              </div>
              <div class="right">
                {{ item.type == 1 ? "+" : "-" }}{{ item.point }}
              </div>
            </div>
            <div class="seeMore" v-if="list.length > showNum">
              <div class="btn" @click="showNum += 5">点击查看更多</div>
            </div>
          </div>
        </div>

        <div v-if="tab == 2" class="pointrule">
          <div>
            <img src="@/assets/imgs/1.png" /><span class="tit">积分获取</span>
          </div>
          1. 完善用户信息可获取20积分；<br />
          2. 每日签到可获取10积分，连续签到7天额外奖励50分；<br />
          3. 用户观看视频可以获取相应的积分，按照观看时长获取
          相应的积分数，积分按照各阶段时长对应获取；<br />
          a. 视频每次观看时长10分钟，可获取10积分<br />
          b. 视频每次观看时长20分钟，可获取20积分，依次累加
          <div style="margin-top: 20px">
            <img src="@/assets/imgs/2.png" /><span class="tit">积分使用</span>
          </div>
          1. 使用积分可兑换商品，兑换成功后可在个人中心查看；<br />
          2. 积分兑换商品后，积分实时扣减；<br />
          3. 积分兑换满足兑换商品的条件即可兑换，无数量限制。<br />
          <div style="margin-top:100px;font-size:14px">
            最终解释权归上海纽脉医疗科技有限公司所有。
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="content" v-if="haveLogin">
      <div style="text-align: center; margin-top: 300px; font-size: 28px">
        敬请期待!
      </div>
    </div> -->

    <noLogin @loginLayerShowClick="loginLayerShowClick" v-else />
  </div>
</template>

<script>
import api from "@/api/serves/myCenter";
import noLogin from "./noLogin.vue";
export default {
  name: "myScore",
  props: ["haveLogin"],
  components: {
    noLogin,
  },
  data() {
    return {
      list: [],
      tab: 1,
      showNum: 5,
      userSigns: "",
    };
  },
  methods: {
    loginLayerShowClick() {
      this.$emit("loginLayerShowClick");
    },
    goDuihuan() {
      this.$emit("changeNavAct", 5);
    },
    pointdetailed() {
      api.pointdetailed().then((res) => {
        this.list = res;
      });
    },
    chooseTab(e){
      this.tab = e;
    },
    getuserSigns() {
      api.userSigns().then((res) => {
        this.userSigns = res;
      });
    },
  },
  computed: {
    points() {
      return this.$store.state.user.points;
    },
  },
  mounted() {
    if (this.$store.state.user) {
      this.pointdetailed();
      this.getuserSigns();
    }
  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;
.bold{
  font-weight: bold;
}
.myScore {
  font-size: 24px;
  color: #333;
  .pointrule {
    margin-top: 30px;
    padding-bottom: 50px;
    width: 75%;
    line-height: 60px;
    padding-left:10px;
    .tit {
      margin-left: 20px;
      font-weight: bold;
    }
    img {
      display: inline;
      vertical-align: text-bottom;
      height: 31px;
    }
  }
  .lvPro_box {
    margin-top: 35px;
    height: 127px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 35px;
    font-size: 24px;
    color: #333;
    box-sizing: border-box;
    .pros {
      padding-left: 45px;
      width: 645px;

      .lv {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        margin-bottom: 10px;
      }

      .pro_bar {
        height: 8px;
        background: #e4e4e4;
        border-radius: 10px;
        position: relative;
        .bar {
          width: 100px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: #20a5e3;
          border-radius: 10px;
        }
      }
    }

    .goDuihuan {
      margin-right: 27px;
      cursor: pointer;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .left {
      display: flex;
      font-size: 36px;
      position: relative;
      .icon {
        display: flex;
        align-items: center;
        border-bottom: 4px solid @skyBlue;
      }

      .name {
        margin-left: 10px;
        padding: 0 10px;
        border-bottom: 4px solid @sBlue;
      }

      div {
        padding-bottom: 10px !important;
      }
    }

    .right {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      position: absolute;
      bottom: 1px;
      z-index: -1;
    }
  }

  .t_tips {
    padding: 23px 57px;
  }

  .score_box {
    background: #fff;
    padding: 0 27px 0 45px;
    .top_t {
      text-align: center;
      line-height: 66px;
      position: relative;
      border-bottom: 1px solid #c7c7c7;
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .list {
      padding-bottom: 20px;
      .item {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        .left {
          .time {
            font-size: 18px;
          }
        }

        .right {
          width: 142px;
          line-height: 50px;
          text-align: right;
          color: #fff;
          font-size: 36px;
          background: #005aa4;
          border-radius: 10px;
          padding-right: 16px;
          box-sizing: border-box;
        }
      }

      .seeMore {
        text-align: center;

        .btn {
          display: inline-block;
          color: #20a5e3;
          margin-top: 70px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>