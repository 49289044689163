<template>
  <div class="scoreShop">
    <div class="title">
      <div class="left">
        <div class="icon">
          <img src="@/assets/imgs/my/p2_5.png" alt="" />
        </div>
        <div class="name">积分商城</div>
      </div>
    </div>
    <div class="content" v-if="haveLogin">
      <div
        class="item"
        v-for="(item, key) in shopList"
        :key="key"
        v-if="key < showNum"
      >
        <img class="cover_img" :src="item.coverimg" alt="" />
        <div class="name">{{ item.title }}</div>
        <div class="scroe">
          <i class="num">{{ item.point }}</i
          >积分
        </div>
        <div class="btn" @click="submit(item.id, item.title)">兑换</div>
      </div>
      <div class="seeMore" v-if="shopList.length > showNum">
        <div class="btn" @click="showNum += 3">点击查看更多</div>
      </div>
    </div>

    <!-- <div class="content" v-if="haveLogin">
      <div style="text-align: center; margin-top: 300px; font-size: 28px">敬请期待!</div>
    </div> -->
    <noLogin @loginLayerShowClick="loginLayerShowClick" v-else />
  </div>
</template>

<script>
import api from "@/api/serves/myCenter";
import noLogin from "./noLogin.vue";
export default {
  name: "scoreShop",
  props: ["haveLogin"],
  components: {
    noLogin,
  },
  data() {
    return {
      shopList: [],
      showNum: 3,
    };
  },
  methods: {
    loginLayerShowClick() {
      this.$emit("loginLayerShowClick");
    },
    getShopList() {
      api.getShopList().then((res) => {
        this.shopList = res;
      });
    },
    submit(id, title) {
      this.$confirm("您确定要兑换" + title + "吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        api.convert({ id }).then((res) => {
          this.$message.success("兑换成功");
          api.getUser()
          .then(res => {
            this.$store.state.user = res;
          })
        });
      });
    },
  },
  mounted() {
    if(this.$store.state.user) {
      this.getShopList();
    }
  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;

.scoreShop {
  .content {
    padding-top: 50px;
    .item {
      display: inline-block;
      width: 288px;
      height: 525px;
      border-radius: 10px;
      background: #fff;
      font-size: 18px;
      overflow: hidden;
      margin-right: 20px;
      margin-bottom: 20px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .cover_img {
        width: 100%;
        height: 270px;
        background: #000;
      }

      .name {
        width: 80%;
        margin: 30px auto 40px;
      }

      .scroe {
        font-size: 24px;
        text-align: center;
        color: #20a5e3;

        .num {
          font-size: 36px;
        }
      }

      .btn {
        width: 246px;
        line-height: 46px;
        text-align: center;
        background: #20a5e3;
        border-radius: 30px;
        color: #fff;
        margin: 30px auto;
        cursor: pointer;
      }
    }
    .seeMore {
      margin-top: 20px;
      text-align: center;

      .btn {
        display: inline-block;
        font-size: 24px;
        color: #20a5e3;
        cursor: pointer;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .left {
      display: flex;
      font-size: 36px;
      position: relative;
      .icon {
        display: flex;
        align-items: center;
        border-bottom: 4px solid @skyBlue;
      }

      .name {
        margin-left: 10px;
        padding: 0 10px;
        border-bottom: 4px solid @sBlue;
      }

      div {
        padding-bottom: 10px !important;
      }
    }

    .right {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      position: absolute;
      bottom: 1px;
      z-index: -1;
    }
  }
}
</style>