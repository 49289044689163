<template>
  <div class="myCenter">
    <Header :active="'myCenter'" />
    <div class="container">
      <div class="left_side">
        <div class="user_info">
          <img
            class="avatar"
            v-if="!haveLogin"
            src="@/assets/imgs/my/p1_1.png"
            alt=""
          />
          <img class="avatar" v-else :src="userInfo.avatar" alt="" />
          <div class="name" v-if="haveLogin">
            欢迎您 <span>{{ userInfo.name }}</span>
          </div>
          <div class="tips" v-else>
            登录观看直播、<br />
            领取福利、订阅信息
          </div>
          <div class="btn" @click="loginLayerShowClick">
            {{ !haveLogin ? "立即登录" : "退出登录" }}
          </div>
        </div>

        <div class="rw_center" @click="changeNavAct(-1)">
          <div class="top" :class="navActive == -1 ? 'active' : ''">
            <img class="icon" src="@/assets/imgs/my/p1_2.png" alt="" />
            任务中心
          </div>

          <div class="bottom">
            <i class="red"></i> 你有福利待领取 <i class="arrow">》</i>
          </div>
        </div>

        <div class="center_box">
          <!-- <div
            class="item"
            :class="navActive == 0 ? 'active' : ''"
            @click="changeNavAct(0)"
          >
            <img class="icon" src="@/assets/imgs/my/p1_3.png" alt="" />
            <span>我的预约</span>
          </div> -->
          <div
            class="item"
            :class="navActive == 1 ? 'active' : ''"
            @click="changeNavAct(1)"
          >
            <img class="icon" src="@/assets/imgs/my/p1_4.png" alt="" />
            <span>观看历史</span>
          </div>
        </div>

        <div class="my_sf">
          <div class="title">我的身份</div>

          <div class="list">
            <div
              class="item"
              :class="navActive == 2 ? 'active' : ''"
              @click="changeNavAct(2)"
            >
              <img class="icon" src="@/assets/imgs/my/p1_5.png" alt="" />
              <span>个人资料</span>
            </div>
            <div
              class="item"
              :class="navActive == 3 ? 'active' : ''"
              @click="changeNavAct(3)"
            >
              <img class="icon" src="@/assets/imgs/my/p1_6.png" alt="" />
              <span>我的积分</span>
            </div>
            <!-- <div
              class="item"
              :class="navActive == 4 ? 'active' : ''"
              @click="changeNavAct(4)"
            >
              <img class="icon" src="@/assets/imgs/my/p1_7.png" alt="" />
              <span>我的兑换</span>
            </div>
            <div
              class="item"
              :class="navActive == 5 ? 'active' : ''"
              @click="changeNavAct(5)"
            >
              <img class="icon" src="@/assets/imgs/my/p1_8.png" alt="" />
              <span>积分商城</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="right_side">
        <renwuCenter
          v-if="navActive == -1"
          @loginLayerShowClick="loginLayerShowClick"
          :haveLogin="haveLogin"
          @changeNavAct="changeNavAct"
        />
        <myYuyue
          v-if="navActive == 0"
          @loginLayerShowClick="loginLayerShowClick"
          :haveLogin="haveLogin"
        />
        <seeHistory
          v-if="navActive == 1"
          @loginLayerShowClick="loginLayerShowClick"
          :haveLogin="haveLogin"
        />
        <myInfo
          v-if="navActive == 2"
          @loginLayerShowClick="loginLayerShowClick"
          :haveLogin="haveLogin"
        />
        <myScore
          v-if="navActive == 3"
          @loginLayerShowClick="loginLayerShowClick"
          :haveLogin="haveLogin"
          @changeNavAct="changeNavAct"
        />
        <myDuihuan
          v-if="navActive == 4"
          @loginLayerShowClick="loginLayerShowClick"
          :haveLogin="haveLogin"
        />
        <scoreShop
          v-if="navActive == 5"
          @loginLayerShowClick="loginLayerShowClick"
          :haveLogin="haveLogin"
        />
      </div>
    </div>

    <loginLayer :layerShow="loginLayerShow" @closeLayer="closeLayer" />
  </div>
</template>

<script>
import Header from "@/components/header";
import loginLayer from "@/components/login";
import { removeToken } from "@/utils/auth";
import api from "@/api/serves/myCenter";
import renwuCenter from "./components/renwuCenter.vue";
import myYuyue from "./components/myYuyue.vue";
import seeHistory from "./components/seeHistory.vue";
import myInfo from "./components/myInfo.vue";
import myScore from "./components/myScore.vue";
import myDuihuan from "./components/myDuihuan.vue";
import scoreShop from "./components/scoreShop.vue";

export default {
  name: "myCenter",
  components: {
    Header,
    loginLayer,
    renwuCenter,
    myYuyue,
    seeHistory,
    myInfo,
    myScore,
    myDuihuan,
    scoreShop,
  },
  data() {
    return {
      navActive: -1,
      loginLayerShow: false,
      haveLogin: false,
      // userInfo: "",
    };
  },
  computed: {
    act() {
      return this.$route.query.act;
    },
    userInfo() {
      return this.$store.state.user;
    },
  },
  watch: {
    act(val) {
      this.navActive = val;
    },
  },
  methods: {
    changeNavAct(idx) {
      this.navActive = idx;
      this.$router.push('/myCenter?act=' + idx) 
    },
    closeLayer() {
      this.loginLayerShow = false;
    },
    loginLayerShowClick() {
      if (this.haveLogin) {
        this.$confirm("是否退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          removeToken();
          window.location.href = '/logout'
          // this.$message.success("退出成功!");
          setTimeout(() => {
            location.reload();
          }, 500);
        });
      } else {
        this.loginLayerShow = true;
      }
    },
  },
  mounted() {
    this.navActive = this.$route.query.act || -1;
    if (this.$store.state.user && this.$store.state.user != "youke") {
      this.haveLogin = true;
    }
  },
};
</script>

<style lang="less" scoped>
.myCenter {
  padding: 135px 0;
  background: #f3f3f3;

  .container {
    width: 1250px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .left_side {
      width: 300px;

      .user_info {
        height: 245px;
        margin-top: 32px;
        text-align: center;
        font-size: 18px;

        .avatar {
          width: 110px;
          height: 110px;
          border-radius: 50%;
          margin: 0 auto;
        }

        .name {
          font-size: 24px;
          color: #333333;
          margin-top: 20px;

          span {
            font-weight: 700;
          }
        }

        .tips {
          margin-top: 15px;
          color: #333333;
        }

        .btn {
          width: 109px;
          text-align: center;
          line-height: 37px;
          border: 1px solid #333333;
          border-radius: 30px;
          margin: 0 auto;
          margin-top: 15px;
          cursor: pointer;
        }
      }

      .rw_center {
        height: 147px;
        background: #fff;
        padding-left: 50px;
        padding-top: 20px;
        font-size: 18px;
        box-sizing: border-box;
        cursor: pointer;
        .top {
          height: 67px;
          display: flex;
          align-items: center;

          .icon {
            margin-right: 22px;
          }
        }

        .bottom {
          color: #999999;
          .red {
            display: inline-block;
            width: 13px;
            height: 13px;
            background: #ff3b55;
            border-radius: 50%;
            margin-right: 3px;
          }

          .arrow {
            margin-left: 5px;
          }
        }
      }

      .center_box {
        margin-top: 19px;
        // height: 175px;
        height: 108px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .item {
          height: 67px;
          display: flex;
          align-items: center;
          font-size: 18px;
          cursor: pointer;
          padding-left: 42px;
          margin-top: 0 !important;
          .icon {
            margin-right: 15px;
          }

          &:last-child {
            margin-top: 10px;
          }
        }
      }

      .active {
        background: url("../../assets/imgs/my/p1_10.png") no-repeat right;
      }

      .my_sf {
        height: 360px;
        background: #fff;
        margin-top: 12px;
        .title {
          font-size: 24px;
          padding-top: 20px;
          padding-left: 40px;
        }

        .list {
          margin-top: 20px;

          .item {
            width: 100%;
            height: 67px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 18px;
            cursor: pointer;
            padding-right: 130px;
            box-sizing: border-box;

            &:nth-child(1) .icon,
            &:nth-child(3) .icon {
              margin-right: 22px;
            }
            &:nth-child(2) .icon {
              margin-right: 18px;
            }
            &:nth-child(4) .icon {
              margin-right: 24px;
            }
          }
        }
      }
    }

    .right_side {
      width: 905px;
      padding-top: 60px;
    }
  }
}
</style>