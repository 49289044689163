<template>
  <div class="myDuihuan">
    <div class="title">
      <div class="left">
        <div class="icon">
          <img src="@/assets/imgs/my/p2_4.png" alt="" />
        </div>
        <div class="name">我的兑换</div>
      </div>
    </div>
    <div class="content" v-if="haveLogin">
      <div v-if="list.length == 0" style="text-align: center; margin-top: 300px; font-size: 28px">暂无兑换</div>

      <div
        class="item"
        v-for="(item, key) in list"
        :key="key"
        v-if="key < showNum"
      >
        <img class="cover_img" :src="item.coverimg" alt="" />
        <div class="info">
          <div class="name">{{ item.title }}</div>
          <div class="score">消耗积分: {{ item.point }}分</div>
          <div class="time">兑换时间: {{ item.date }}</div>
        </div>
      </div>

      <div class="seeMore" v-if="list.length > showNum">
        <div class="btn" @click="showNum += 3">点击查看更多</div>
      </div>
    </div>

    <!-- <div class="content" v-if="haveLogin">
      <div style="text-align: center; margin-top: 300px; font-size: 28px">敬请期待!</div>
    </div> -->
    <noLogin @loginLayerShowClick="loginLayerShowClick" v-else />
  </div>
</template>

<script>
import api from "@/api/serves/myCenter";
import noLogin from "./noLogin.vue";
export default {
  name: "myDuihuan",
  props: ["haveLogin"],
  components: {
    noLogin,
  },
  data() {
    return {
      list: [],
      showNum: 3,
    };
  },
  methods: {
    loginLayerShowClick() {
      this.$emit("loginLayerShowClick");
    },
    getConverts() {
      api.getConverts().then((res) => {
        this.list = res;
      });
    },
  },
  mounted() {
    if(this.$store.state.user) {
      this.getConverts();
    }
  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;

.myDuihuan {
  .content {
    padding-top: 50px;
    font-size: 24px;
    .item {
      margin-bottom: 20px;
      height: 240px;
      background: #fff;
      position: relative;

      .cover_img {
        width: 250px;
        height: 200px;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        background: #000;
      }

      .info {
        width: 600px;
        position: absolute;
        left: 280px;

        .name {
          position: absolute;
          top: 35px;
        }

        .score {
          position: absolute;
          top: 130px;
          color: #005aa4;
        }

        .time {
          position: absolute;
          top: 190px;
          font-size: 18px;
          color: #666666;
        }
      }
    }

    .seeMore {
      text-align: center;

      .btn {
        display: inline-block;
        color: #20a5e3;
        cursor: pointer;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .left {
      display: flex;
      font-size: 36px;
      position: relative;
      .icon {
        display: flex;
        align-items: center;
        border-bottom: 4px solid @skyBlue;
      }

      .name {
        margin-left: 10px;
        padding: 0 10px;
        border-bottom: 4px solid @sBlue;
      }

      div {
        padding-bottom: 10px !important;
      }
    }

    .right {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      position: absolute;
      bottom: 1px;
      z-index: -1;
    }
  }
}
</style>