<template>
  <div class="renwuCenter">
    <div class="title">
      <div class="left">
        <div class="icon">
          <img src="@/assets/imgs/my/p2_1.png" alt="" />
        </div>
        <div class="name">任务中心</div>
      </div>
    </div>
    <div class="content" v-if="haveLogin">
      <div class="lvPro_box">
        <div class="pros">
          <div class="lv">
            <span>{{ userSigns.levels.level }}</span>
            <span>{{ userSigns.levels.points }}</span>
          </div>
          <div class="pro_bar">
            <div
              class="bar"
              :style="`width: ${userSigns.levels.progress}`"
            ></div>
          </div>
        </div>
        <!-- <div class="goDuihuan" @click="goDuihuan">兑换商品》</div> -->
      </div>
      <div class="t_tips">做任务解锁特权和奖励</div>
      <div class="qiandao_box">
        <div class="day">
          已连续签到 <i>{{ userSigns.count }}</i> 天
        </div>
        <div class="tips">连续签到7天可额外获取50积分</div>
        <div class="qiandao">
          <div
            class="item"
            :class="
              item.state == 3 ? 'active activeNo' : item.state == 2 ? 'no' : ''
            "
            v-for="(item, key) in userSigns.data"
            :key="key"
            @click="qiandaoClick(item.state)"
          >
            <div class="top">{{ item.title }}</div>
            <div class="bottom">
              <div class="text" v-if="item.state != 1">
                {{ item.state == 2 ? "未签到" : item.state == 3 ? "签到" : "" }}
              </div>
              <img
                v-if="item.state == 1"
                src="@/assets/imgs/my/p4_1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="look_time_box">
        <div class="l_title">
          <div class="left">累计观看直播(已累计{{userSigns.userTimes}}分)</div>
          <div class="right" @click="golive">去观看》</div>
        </div>
        <div class="list">
          <div class="item" v-for="(item, key) in userSigns.times" :key="key">
            <div class="state">
              <div v-if="item.state == 2">
                <img class="state_img" src="@/assets/imgs/my/p4_2.png" alt="" />
              </div>
              <div v-else>
                <img class="icon" src="@/assets/imgs/my/p4_3.png" alt="" />
                <img class="state_img" src="@/assets/imgs/my/p4_4.png" alt="" />
              </div>
            </div>
            <div class="text">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content" v-if="haveLogin">
      <div style="text-align: center; margin-top: 300px; font-size: 28px">敬请期待!</div>
    </div> -->
    <noLogin @loginLayerShowClick="loginLayerShowClick" v-else />
  </div>
</template>

<script>
import api from "@/api/serves/myCenter";
import noLogin from "./noLogin.vue";
export default {
  name: "renwuCenter",
  props: ["haveLogin"],
  components: {
    noLogin,
  },
  data() {
    return {
      // points: "",
      userSigns: "",
    };
  },
  computed: {
    points() {
      return this.$store.state.user.points;
    },
  },
  methods: {
    loginLayerShowClick() {
      this.$emit("loginLayerShowClick");
    },
    qiandaoClick(state) {
      if (state == 3) {
        api.userSigned().then((res) => {
          this.$message.success("签到成功");
          this.getuserSigns();
        });
      }
    },
    goDuihuan() {
      this.$emit("changeNavAct", 5);
    },
    golive() {
      this.$router.push("/live");
    },
    getuserSigns() {
      api.userSigns().then((res) => {
        this.userSigns = res;
        console.log(res);
      });
    },
  },
  mounted() {
    if (this.$store.state.user) {
      this.getuserSigns();
    }
  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;

.renwuCenter {
  .content {
    font-size: 24px;
    color: #333;
    .lvPro_box {
      margin-top: 35px;
      height: 127px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 35px;
      font-size: 24px;
      color: #333;
      box-sizing: border-box;
      .pros {
        padding-left: 45px;
        width: 645px;

        .lv {
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          margin-bottom: 10px;
        }

        .pro_bar {
          height: 8px;
          background: #e4e4e4;
          border-radius: 10px;
          position: relative;
          .bar {
            width: 100px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #20a5e3;
            border-radius: 10px;
          }
        }
      }

      .goDuihuan {
        margin-right: 27px;
        cursor: pointer;
      }
    }

    .t_tips {
      padding: 23px 57px;
    }

    .qiandao_box {
      padding: 0 28px 0 52px;
      height: 345px;
      background: #fff;

      .day {
        font-weight: 700;
        padding-top: 40px;

        i {
          margin: 0 30px;
        }
      }

      .tips {
        padding: 15px 0;
        border-bottom: 1px solid #c7c7c7;
      }

      .qiandao {
        margin-top: 25px;
        display: flex;
        .item {
          width: 75px;
          height: 145px;
          border: 1px solid #c7c7c7;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          margin-right: 20px;
          .text {
            font-size: 18px;
          }
        }

        .active {
          background: #005aa4;
          color: #fff;
          box-shadow: 0px 2px 5px #ccc;
        }

        .activeNo {
          cursor: pointer;
        }

        .no {
          box-shadow: 0px 2px 5px #ccc;
          border: none;
          background: #f6f6f6;
        }
      }
    }

    .look_time_box {
      margin-top: 18px;
      background: #fff;
      height: 265px;
      padding: 0 28px 0 52px;

      .l_title {
        padding-top: 50px;
        padding-bottom: 12px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #c7c7c7;

        .left {
          font-weight: 700;
        }

        .right {
          cursor: pointer;
        }
      }

      .list {
        margin-top: 42px;
        display: flex;

        .item {
          position: relative;
          width: 75px;
          font-size: 18px;
          margin-right: 20px;

          .state_img {
            margin: 0 auto;
          }

          .icon {
            position: absolute;
            top: -5px;
            right: 8px;
          }

          .text {
            margin-top: 22px;
            text-align: center;
          }
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .left {
      display: flex;
      font-size: 36px;
      position: relative;
      .icon {
        display: flex;
        align-items: center;
        border-bottom: 4px solid @skyBlue;
      }

      .name {
        margin-left: 10px;
        padding: 0 10px;
        border-bottom: 4px solid @sBlue;
      }

      div {
        padding-bottom: 10px !important;
      }
    }

    .right {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      position: absolute;
      bottom: 1px;
      z-index: -1;
    }
  }
}
</style>