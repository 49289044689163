<template>
  <div class="myYuyue">
    <div class="title">
      <div class="left">
        <div class="icon">
          <img src="@/assets/imgs/my/p2_1.png" alt="" />
        </div>
        <div class="name">我的预约</div>
      </div>
    </div>
    <div class="content" v-if="haveLogin">
      <div class="list">
        <div class="item" v-for="(item, key) in list" :key="key" v-if="key < showNum">
          <div class="info">{{item.title}}</div>
          <div class="time">{{item.date}}</div>
        </div>
      </div>
      <div class="seeMore" v-if="showNum < list.length"><div class="btn" @click="seeMore">点击查看更多</div></div>
    </div>
    <noLogin @loginLayerShowClick="loginLayerShowClick" v-else />
  </div>
</template>

<script>
import api from "@/api/serves/myCenter";
import noLogin from "./noLogin.vue";
export default {
  name: "myYuyue",
  props: ["haveLogin"],
  components: {
    noLogin,
  },
  data() {
    return {
      list: [],
      showNum: 6
    };
  },
  methods: {
    loginLayerShowClick() {
      this.$emit("loginLayerShowClick");
    },
    getSubscribes() {
      api.subscribes()
      .then(res => {
        this.list = res
      })
    },
    seeMore() {
      this.showNum += 6;
    }
  },
  mounted() {
    if(this.$store.state.user) {
      this.getSubscribes();
    }
  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;

.myYuyue {
  .content {
    .list {
      margin-top: 55px;
      .item {
        height: 108px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cccccc;
        font-size: 24px;
        padding: 0 38px 0 15px;

        .info {
          max-width: 700px;
          color: #333;
        }

        .time {
          color: #999999;
        }
      }
    }

    .seeMore {
      text-align: center;
      margin-top: 100px;
      .btn {
        display: inline-block;
        font-size: 24px;
        color: @skyBlue;
        cursor: pointer;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .left {
      display: flex;
      font-size: 36px;
      position: relative;
      .icon {
        display: flex;
        align-items: center;
        border-bottom: 4px solid @skyBlue;
      }

      .name {
        margin-left: 10px;
        padding: 0 10px;
        border-bottom: 4px solid @sBlue;
      }

      div {
        padding-bottom: 10px !important;
      }
    }

    .right {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      position: absolute;
      bottom: 1px;
      z-index: -1;
    }
  }
}
</style>