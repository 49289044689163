<template>
  <div class="seeHistory">
    <div class="title">
      <div class="left">
        <div class="icon">
          <img src="@/assets/imgs/my/p2_2.png" alt="" />
        </div>
        <div class="name">观看历史</div>
      </div>
    </div>
    <div class="content" v-if="haveLogin">
      <div
        v-if="list.length == 0"
        style="text-align: center; margin-top: 300px; font-size: 28px"
      >
        暂无观看记录
      </div>

      <div class="course_list">
        <div
          class="course_item"
          v-for="(item, key) in list"
          :key="key"
          v-if="key < showNum"
          @click="goLive(item.id, 'lookBack')"
        >
          <div class="img_box">
            <el-image class="big_img" :src="item.coverimg" >
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <div class="status">
              <img
                v-if="item.subscribe == 1"
                src="@/assets/imgs/p1_18.png"
                alt=""
              />
              <img
                v-if="item.subscribe == 0"
                @click.stop="yuyueClick(item.id)"
                src="@/assets/imgs/p1_19.png"
                alt=""
              />
              <img
                v-if="item.subscribe == 3"
                src="@/assets/imgs/p1_17.png"
                alt=""
              />
            </div>
          </div>
          <div class="s_name text_2_hidden">{{ item.title }}</div>
          <div class="time">{{ item.date }}</div>
        </div>
      </div>

      <div class="loadMore" @click="loadMore" v-if="showNum < list.length">
        LOAD MORE
      </div>
    </div>
    <noLogin @loginLayerShowClick="loginLayerShowClick" v-else />
    <subscribeLayer
      :subscribeLayerShow="subscribeLayerShow"
      @onSubscribeLayerShow="onSubscribeLayerShow"
    />
  </div>
</template>

<script>
import indexApi from "@/api/serves/index";
import api from "@/api/serves/myCenter";
import noLogin from "./noLogin.vue";
import subscribeLayer from "@/components/subscribeLayer";

export default {
  name: "seeHistory",
  props: ["haveLogin"],
  components: {
    noLogin,
    subscribeLayer,
  },
  data() {
    return {
      list: [],
      showNum: 6,
      subscribeLayerShow: false,
    };
  },
  methods: {
    loginLayerShowClick() {
      this.$emit("loginLayerShowClick");
    },
    getList() {
      api.history().then((res) => {
        this.list = res;
      });
    },
    goLive(id, path) {
      this.$router.push({
        path,
        query: { id },
      });
    },
    yuyueClick(liveid) {
      indexApi.subscribe({ liveid }).then((res) => {
        this.subscribeLayerShow = true;
        this.getVideoArr();
      });
    },
    onSubscribeLayerShow() {
      this.subscribeLayerShow = false;
    },
    loadMore() {
      this.showNum += 6;
    },
  },
  mounted() {
    if (this.$store.state.user) {
      this.getList();
    }
  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;

.seeHistory {
  .content {
    .course_list {
      margin-top: 58px;
      display: flex;
      flex-wrap: wrap;

      .course_item {
        width: 300px;
        margin-right: 2px;
        margin-bottom: 50px;
        cursor: pointer;
        position: relative;
        padding-bottom: 30px;

        .img_box {
          position: relative;
          border-radius: 10px;
          overflow: hidden;

          .big_img {
            width: 100%;
            height: 169px;
            transition: 0.5s all;
            border-radius: 10px;
            .image-slot {
              display: flex;
              align-items: center;
              justify-content: center;
              color: #c0c4cc;
              background: #f5f7fa;
              height: 169px;
              border-radius: 10px;
            }
            &:hover {
              transform: scale(1.2);
            }
          }
          .status {
            position: absolute;
            bottom: 10px;
            left: 20px;
          }
        }

        .s_name {
          margin-top: 20px;
          font-size: 24px;
        }

        .time {
          position: absolute;
          top: 260px;
          margin-top: 10px;
          font-size: 18px;
          margin-top: 4px;
          color: #999999;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    .loadMore {
      margin: 70px auto 0;
      width: 230px;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      color: #666666;
      border: 1px solid #666666;
      cursor: pointer;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .left {
      display: flex;
      font-size: 36px;
      position: relative;
      .icon {
        display: flex;
        align-items: center;
        border-bottom: 4px solid @skyBlue;
      }

      .name {
        margin-left: 10px;
        padding: 0 10px;
        border-bottom: 4px solid @sBlue;
      }

      div {
        padding-bottom: 10px !important;
      }
    }

    .right {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      position: absolute;
      bottom: 1px;
      z-index: -1;
    }
  }
}
</style>