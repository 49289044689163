<template>
  <div class="no_login">
      <div class="tips">请用公司邮箱登录进行相关操作</div>
      <div class="btn" @click="loginLayerShowClick">立即登录</div>
  </div>
</template>

<script>
export default {
  name: "noLogin",
  components: {},
  data() {
    return {};
  },
  methods: {
      loginLayerShowClick() {
          this.$emit('loginLayerShowClick')
      }
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.no_login {
    font-size: 18px;
    .tips {
        color: #333333;
        width: 275px;
        line-height: 100px;
        border: 1px solid #333333;
        text-align: center;
        border-radius: 10px;
        margin: 285px auto 0;
    }

    .btn {
        width: 110px;
        line-height: 38px;
        text-align: center;
        margin: 52px auto 0;
        background: #2ca6e0;
        color: #fff;
        border-radius: 30px;
        cursor: pointer;
    }
}
</style>