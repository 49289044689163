<template>
  <div class="myInfo">
    <div class="title">
      <div class="left">
        <div class="icon">
          <img src="@/assets/imgs/my/p2_3.png" alt="" />
        </div>
        <div class="name">个人资料</div>
      </div>
    </div>
    <div class="content" v-if="haveLogin">
      <div class="avatar_box">
        <img class="avatar" :src="userInfo.avatar" alt="" />
        <div class="btn">
          <img src="@/assets/imgs/my/p3_1.png" alt="" />
          <input
            class="upload"
            type="file"
            @change="uploadImg"
            accept="image/png,image/jpeg"
          />
        </div>
      </div>

      <div class="info_box">
        <div class="info_item">
          <div class="name">姓名</div>
          <input type="text" maxlength="6" v-model="userInfo.name" />
        </div>
        <div class="info_item" v-if="userInfo.inside == 1">
          <div class="name">员工号</div>
          <input type="text" disabled v-model="userInfo.numbers" />
        </div>
        <div class="info_item">
          <div class="name">手机号码</div>
          <input type="text" disabled v-model="userInfo.phone" />
        </div>
        <div class="info_item">
          <div class="name">生日</div>
          <!-- <input type="text" v-model="userInfo.birthday" /> -->
          <el-date-picker v-model="userInfo.birthday" value-format="yyyy-MM-dd HH:mm:ss"> type="date">
          </el-date-picker>
        </div>
        <div class="address_box">
          <div class="name">所在城市</div>
          <div class="address">
            <div class="pro item">
              <img class="zuobiao" src="@/assets/imgs/my/p3_2.png" alt="" />
              <select v-model="userInfo.province">
                <option v-for="(item, key) in cityJson" :key="key" :value="key">
                  {{ key }}
                </option>
              </select>
              <img class="icon" src="@/assets/imgs/my/p3_3.png" alt="" />
            </div>
            <div class="city item">
              <select v-model="userInfo.city">
                <option
                  v-for="(item, key) in cityJson[userInfo.province]"
                  :key="key"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
              <img class="icon" src="@/assets/imgs/my/p3_3.png" alt="" />
            </div>
          </div>
        </div>
        <div class="btn" @click="update">确认修改</div>
      </div>
    </div>
    <noLogin @loginLayerShowClick="loginLayerShowClick" v-else />
  </div>
</template>

<script>
import api from "@/api/serves/myCenter";
import noLogin from "./noLogin.vue";
import cityJson from "@/utils/city.js";
import axios from "axios";
import { Loading } from "element-ui";
import { getToken } from "@/utils/auth";
export default {
  name: "myInfo",
  props: ["haveLogin"],
  components: {
    noLogin,
  },
  data() {
    return {
      userInfo: "",
      cityJson: "",
      imgFile: null,
    };
  },
  methods: {
    loginLayerShowClick() {
      this.$emit("loginLayerShowClick");
    },
    deepCopy(obj) {
      var result = Array.isArray(obj) ? [] : {};
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            result[key] = deepCopy(obj[key]); //递归复制
          } else {
            result[key] = obj[key];
          }
        }
      }
      return result;
    },
    update() {
      var loading = Loading.service();
      var formData = new FormData();

      if (this.imgFile) {
        formData.append("avatar", this.imgFile);
      } else {
        formData.append("avatar", this.userInfo["avatar"]);
      }

      formData.append(
        "birthday",
        this.userInfo["birthday"] ? this.userInfo["birthday"] : ""
      );
      formData.append("name", this.userInfo["name"]);
      formData.append("province", this.userInfo["province"]);
      formData.append("city", this.userInfo["city"]);
      formData.append("token", getToken());
      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post("https://newmed.trisanet.com/api/user/update", formData, config)
        .then((res) => {
          var res = res.data;
          if (res.code == 200) {
            api.getUser().then((res) => {
              this.$store.state.user = res;
            });
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.msg);
          }
          loading.close();
        })
        .catch(() => {
          loading.close();
          this.$message.error("发生错误，请稍后再试");
        });
    },
    uploadImg(e) {
      var loading = Loading.service();
      var file = e.target.files[0];
      var render = new FileReader(),
        that = this;
      that.imgFile = file;
      render.readAsDataURL(file);
      render.onload = function (e) {
        that.userInfo.avatar = this.result;
        // 以服务的方式调用的 Loading 需要异步关闭
        loading.close();
      };
    },
  },
  mounted() {
    this.userInfo = this.deepCopy(this.$store.state.user);
    this.cityJson = cityJson;
  },
};
</script>

<style lang="less" scoped>
@skyBlue: #20a5e3;
@sBlue: #005aa4;

.myInfo {
  .content {
    display: flex;
    margin-top: 112px;
    .avatar_box {
      width: 190px;
      height: 190px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      .avatar {
        width: 100%;
        height: 100%;
      }

      .btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);

        .upload {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
    }

    .info_box {
      // margin-left: 70px;
      flex: 1;
      font-size: 24px;

      .info_item {
        display: flex;
        align-items: center;
        color: #333;
        margin-bottom: 30px;
        .name {
          width: 224px;
          text-align: center;
        }
        input,
        .el-date-editor /deep/ .el-input__inner {
          width: 390px;
          height: 48px;
          background: #fff;
          border-radius: 30px;
          font-size: 24px;
          color: #333;
          padding: 0 20px;
          box-sizing: border-box;
          border: none;
        }
        .el-date-editor {
          width: 390px;

          /deep/ .el-input__prefix {
            display: none;
          }
        }
      }

      .address_box {
        margin-top: 60px;
        margin-left: 70px;

        .address {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          .item {
            width: 290px;
            border-bottom: 1px solid #ccc;
            position: relative;

            select {
              width: 100%;
              height: 45px;
              border: none;
              background: none;
              -webkit-appearance: none;
              font-size: 24px;
            }

            .icon {
              position: absolute;
              right: 11px;
              bottom: 13px;
            }
          }
        }

        .pro {
          .zuobiao {
            position: absolute;
            bottom: 5px;
            left: 6px;
          }

          select {
            padding-left: 40px;
          }
        }
      }

      .btn {
        margin: 130px auto 0;
        width: 265px;
        text-align: center;
        line-height: 63px;
        background: @skyBlue;
        color: #fff;
        border-radius: 30px;
        cursor: pointer;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;

    .left {
      display: flex;
      font-size: 36px;
      position: relative;
      .icon {
        display: flex;
        align-items: center;
        border-bottom: 4px solid @skyBlue;
      }

      .name {
        margin-left: 10px;
        padding: 0 10px;
        border-bottom: 4px solid @sBlue;
      }

      div {
        padding-bottom: 10px !important;
      }
    }

    .right {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #c7c7c7;
      position: absolute;
      bottom: 1px;
      z-index: -1;
    }
  }
}
</style>